<template>
	<teleport to="body">
		<Popup v-if="popup" @close-popup="closePopup" mode="success" :msg="message" />
	</teleport>
	<div class="modal d-block bookinfoModal" :class="$i18n.locale == 'ar' ? 'is-rtl' : ''" @click="closemodal">
		<div class="modal-dialog modal-lg" @click.stop>
			<div class="row modal-content">
				<div class="pl-0 summary entry-summary border-left">
					<div class="d-flex align-items-center">
						<button type="button" @click="closemodal" class="close ml-auto">
							<span>
								<i class="fas fa-times ml-2 mt-2"></i>
							</span>
						</button>
					</div>
					<div class="js-slick-carousel mx-auto u-slick woocommerce-product-gallery__wrapper" data-pagi-classes="text-center u-slick__pagination my-4">
						<img :src="book.photo" alt="Image Description" class="mx-auto w-100" />
					</div>

					<div class="px-4 px-xl-7 border-bottom pb-5">
						<h4 class="product_title text-center entry-title mb-3 mt-3">
							{{ book.name }}
						</h4>
						<div class="font-size-2 mb-0">
							<div class="d-flex justify-content-center">
								<div>
									<span class="text-yellow-darker">
										<span :class="book.reviewsTotal > 1 ? 'fas' : 'far'" class="fa-star"></span>
										<span :class="book.reviewsTotal > 1 ? 'fas' : 'far'" class="fa-star"></span>
										<span :class="book.reviewsTotal > 2 ? 'fas' : 'far'" class="fa-star"></span>
										<span :class="book.reviewsTotal > 3 ? 'fas' : 'far'" class="fa-star"></span>
										<span :class="book.reviewsTotal > 4 ? 'fas' : 'far'" class="fa-star"></span>
									</span>
									<span class="ml-3">({{ book.reviewsTotal }})</span>
								</div>
								<div>
									<router-link :class="$i18n.locale == 'ar' ? 'd-flex flex-row-reverse' : ''" :to="`/author/${book.writer.id}`">
										<span class="ml-3 font-weight-medium text-black">
											<bdi>{{ $t('misc.By') }}</bdi>
											({{ $t('misc.author') }})
										</span>
										<span class="ml-2 text-gray-600">
											{{ book.writer.name }}
										</span>
									</router-link>
								</div>
								<div>
									<router-link :class="$i18n.locale == 'ar' ? 'd-flex flex-row-reverse' : ''" :to="`/publisher/${book.publisher.id}`">
										<span class="ml-3 font-weight-medium text-black">
											<bdi>{{ $t('misc.seller') }}</bdi>
											({{ $t('misc.publisher') }})
										</span>
										<span class="ml-2 text-gray-600">
											{{ book.publisher.name }}
										</span>
									</router-link>
								</div>
							</div>
						</div>

						<div class="row">
							<div @click="getHardCopyBookDetails" class="col-2" v-for="(eachBook, index) in book.hardCopy.price_list" :key="index">
								<div
									class="card"
									:data-price="eachBook.current_price"
									:data-branch="eachBook.user_address_id"
									:class="selectedPrice == eachBook.user_address_id ? 'selectedPrice' : ''"
								>
									<p class="price" :data-price="eachBook.current_price" :data-branch="eachBook.user_address_id">{{ eachBook.current_price }}</p>
									<p class="address" :data-branch="eachBook.user_address_id" :data-price="eachBook.current_price">{{ eachBook.title_branch }}</p>
								</div>
							</div>
							<div class="col-2" @click="selectPdfCopy" v-if="book.pdfCopy.status === 1">
								<div class="mt-4">
									<div class="card" :class="selectedPrice == 'pdf' ? 'selectedPrice' : ''">
										<p class="price">{{ book.pdfCopy.price.currrent_price }}</p>
										<p class="address">PDF</p>
									</div>
								</div>
							</div>
						</div>

						<!-- <div>
              
							<span class="woocommerce-Price-currencySymbol d-flex justify-content-around text-center" v-if="currency">
								<div class="pdf" v-if="book.pdfCopy">
									<h5 class="price">{{ $t('misc.pdf') }}</h5>
									<span v-if="book.pdfCopy.status == 1">
										<span v-if="book.pdfCopy.price.offer"> {{ book.pdfCopy.price.offer }} - </span>

										<span :class="book.pdfCopy.price.offer ? 'text-decoration-line-through' : ''">
											{{ book.pdfCopy.price.original }}
										</span>
										{{ currency.sympl }}
									</span>
								</div>
								<div class="hardCopy" v-if="book.hardCopy">
									<h5 class="price">{{ $t('misc.Hardcopy') }}</h5>
									<span v-if="book.hardCopy.status == 1">
										<span v-if="book.hardCopy.price.offer"> {{ book.hardCopy.price.offer }} - </span>

										<span :class="book.hardCopy.price.offer ? 'text-decoration-line-through text-gray-700' : ''">
											{{ book.hardCopy.price.original }}
										</span>
										{{ currency.sympl }}
									</span>
								</div>
							</span>
						</div>

						<div class="mb-2 mt-4 font-size-2" :class="$i18n.locale == 'ar' ? 'text-right' : ''">
							<span class="font-weight-medium"> {{ $t('misc.Book Format') }}: </span>
							<span class="ml-2 text-gray-600">
								{{ $t('misc.Choose an option') }}
							</span>
						</div> -->

						<!-- come back later -->
						<!-- <div class="row mb-4">
							<div class="col mb-3 mb-md-0">
								<div class="" v-if="book.hardCopy.status == 1">
									<input
										id="typeOfListingRadio1"
										type="radio"
										v-model="item.book_type"
										value="hardcopy"
										name="typeOfListingRadio1"
										class="custom-control-input checkbox-outline__input"
									/>
									<label class="border-bottom d-block checkbox-outline__label py-3 px-1 mb-0" for="typeOfListingRadio1">
										<span class="d-block text-center">
											{{ $t('misc.Hardcopy') }}
										</span>
										<span class="d-block text-center fw-bold">
											<span v-if="book.hardCopy.price?.offer"> {{ book.hardCopy.price?.offer }} - </span>

											<span :class="book.hardCopy.price?.offer ? 'text-decoration-line-through text-gray-700' : ''">
												{{ book.hardCopy.price?.original }}
											</span>
											{{ currency.sympl }}
										</span>
									</label>
								</div>
							</div>
							<div class="col mb-3 mb-md-0" v-if="book.pdfCopy.status == 1">
								<div class="">
									<input
										id="typeOfListingRadio2"
										type="radio"
										value="pdfCopy"
										name="typeOfListingRadio1"
										v-model="item.book_type"
										class="custom-control-input checkbox-outline__input"
									/>
									<label class="border-bottom d-block checkbox-outline__label py-3 px-1 mb-0" for="typeOfListingRadio2">
										<span class="d-block text-uppercase text-center d-block text-center">
											{{ $t('misc.pdf') }}
										</span>
										<span class="d-block text-center fw-bold">
											<span v-if="book.pdfCopy.price.offer"> {{ book.pdfCopy.price.offer }} - </span>

											<span :class="book.pdfCopy.price.offer ? 'text-decoration-line-through' : ''">
												{{ book.pdfCopy.price.original }}
											</span>
											{{ currency.sympl }}
										</span>
									</label>
								</div>
							</div>
						</div> -->
						<!-- end come back later -->
						<!-- start editing -->

						<!-- <span class="woocommerce-Price-currencySymbol" v-if="currency">
							<div class="pdf text-center my-4" v-if="book.pdfCopy.status != 0">
								<h5 class="price">{{ $t('misc.pdf') }}</h5>
								<span v-if="book.pdfCopy.status != 0">
									<span v-if="book.pdfCopy.price.offer"> {{ book.pdfCopy.price.offer }} - </span>

									<span :class="book.pdfCopy.price.offer ? 'text-decoration-line-through' : ''">
										{{ book.pdfCopy.price.original }}
									</span>
									{{ currency.sympl }}
								</span>
							</div> -->

						<!-- <div class="hardCopy" :class="$i18n.locale == 'ar' ? ' mr-4' : 'ml-4'" v-if="book.hardCopy.status === 1">
								<h5 class="text-center fs-3 mb-3">{{ $t('misc.Hardcopy') }}</h5>
								<div v-if="book.hardCopy.price_list.length > 0">
									<p class="fw-bold text-end">hardcopy price list and avalable store :</p>
									<div class="row">
										<div class="col align-items-center" v-for="(eachCar, index) in book.hardCopy.price_list" :key="index">
											<p class="fw-bold mb-4 color">
												<span class="fw-normal text-dark">{{ eachCar.title_branch }}</span>
											</p>
											<p class="fw-bold color">
												<span class="fw-normal text-dark">{{ eachCar.pivot.hard_price }}</span>
											</p>
										</div>
									</div>
								</div> -->
						<!-- select the book type you need to buy -->
						<!-- <select class="my-5 p-2 text-center w-100" v-model="optionType">
									<option value="">Select a Book Type</option>
									<option value="pdf">pdf copy</option>
									<option value="hard">hard copy</option>
								</select> -->

						<!-- <div v-if="this.optionType === 'hard'">
									<div v-if="book.hardCopy.price_list.length > 0">
										<p class="fw-bold mb-3">choose the store</p>
										<select class="w-100 p-2 my-3 text-center" v-model="storeOption">
											<option value="">choose the store</option>
											<option v-for="(eachBook, index) in book.hardCopy.price_list" :key="index" :value="eachBook">
												{{ eachBook.title_branch }}
											</option>
										</select>
									</div>
								</div> -->
						<!-- <span v-if="book.hardCopy.status == 1">
												<span v-if="book.hardCopy.price?.offer"> {{ book.hardCopy.price?.offer }} - </span>

												<span :class="book.hardCopy.price?.offer ? 'text-decoration-line-through text-gray-700' : ''">
													{{ book.pdfCopy.price.original }}
												</span>
												{{ currency.sympl }}
											</span> -->

						<!-- end editing -->

						<!-- <div
              class="woocommerce-product-details__short-description font-size-2 mb-5"
            >
              <p :class="$i18n.locale == 'ar' ? 'text-right' : ''">
                {{ book.des }}
              </p>
            </div> -->

						<form @click.prevent class="cart d-md-flex justify-content-center justify-content-md-start align-items-center">
							<div class="quantity mb-4 mb-md-0 d-flex justify-content-center align-items-center">
								<div class="border px-3">
									<div class="js-quantity">
										<div class="d-flex align-items-center">
											<label class="screen-reader-text sr-only">
												{{ $t('misc.Quantity') }}
											</label>
											<button class="js-minus text-dark" @click="qtyMinus">
												<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10px" height="1px">
													<path fill-rule="evenodd" fill="rgb(22, 22, 25)" d="M-0.000,-0.000 L10.000,-0.000 L10.000,1.000 L-0.000,1.000 L-0.000,-0.000 Z" />
												</svg>
											</button>
											<input
												type="number"
												class="input-text qty text form-control text-center border-0"
												step="1"
												min="1"
												max="100"
												name="quantity"
												v-model="item.quntity"
												title="Qty"
											/>
											<button class="js-plus text-dark" @click="qtyPlus">
												<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10px" height="10px">
													<path
														fill-rule="evenodd"
														fill="rgb(22, 22, 25)"
														d="M10.000,5.000 L6.000,5.000 L6.000,10.000 L5.000,10.000 L5.000,5.000 L-0.000,5.000 L-0.000,4.000 L5.000,4.000 L5.000,-0.000 L6.000,-0.000 L6.000,4.000 L10.000,4.000 L10.000,5.000 Z"
													/>
												</svg>
											</button>
										</div>
									</div>
								</div>
							</div>
							<div class="text-center">
								<button
									@click="addToCart"
									name="add-to-cart"
									:class="$i18n.locale == 'ar' ? 'mr-md-4' : 'ml-md-4'"
									class="btn btn-dark border-0 rounded-0 p-3 min-width-250 single_add_to_cart_button button alt"
								>
									{{ $t('misc.Add to cart') }}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { reactive } from 'vue';
	// import { inject } from 'vue'
	import { useStore } from 'vuex';
	// import { useI18n } from 'vue-i18n';
	import { ref } from 'vue';
	export default {
		props: ['book'],
		emits: ['closemodal'],
		computed: {
			cartQuantity() {
				return this.$store.getters.totalQuantity;
			},
			currency() {
				return this.$store.getters.currency;
			},
			popup() {
				return this.$store.getters.popup;
			},
			message() {
				return this.$store.getters.message;
			},
			popupMode() {
				return this.$store.getters.popupMode;
			},
		},
		setup(props, { emit }) {
			const store = useStore();
			// const { t } = useI18n();
			const item = reactive({
				// id: route.params.id,
				quntity: 1,
				book_type: 'hardcopy',
				price: 0,
				totalPrice: 0,
				book_id: props.book.id,
				book: props.book,
				user_address_id: null,
			});
			const optionType = ref('');
			const storeOption = ref('');
			const selectedPrice = ref('');
			function qtyPlus() {
				item.quntity++;
			}
			function qtyMinus() {
				if (item.quntity > 1) item.quntity--;
			}
			function closemodal() {
				emit('closemodal');
			}
			function getHardCopyBookDetails(e) {
				item.price = e.target.dataset.price;
				item.totalPrice = this.item.quntity * e.target.dataset.price;
				item.user_address_id = e.target.dataset.branch;
				item.book_type = 'hardCopy';
				this.selectedPrice = e.target.dataset.branch;

				console.log(e.target.dataset.price);
				console.log(e.target.dataset.branch);
			}
			function selectPdfCopy(e) {
				item.price = e.target.dataset.price;
				item.totalPrice = this.item.quntity * e.target.dataset.price;
				item.user_address_id = '';
				item.book_type = 'pdf';
				this.selectedPrice = 'pdf';

				console.log(e.target.dataset.price);
			}
			function addToCart() {
				console.log(item.book);

				// 	if (item.book_type == 'pdfCopy') {
				// 		item.type = item.book.pdfCopy.text;
				// 		if (item.book.pdfCopy.price.offer) {
				// 			item.price = item.book.pdfCopy.price.offer;
				// 		} else {
				// 			item.price = item.book.pdfCopy.price.original;
				// 		}
				// 	} else {
				// 		item.type = item.book.hardCopy.text;
				// 		if (item.book.hardCopy.price.offer) {
				// 			item.price = item.book.hardCopy.price.offer;
				// 		} else {
				// 			item.price = item.book.hardCopy.price.original;
				// 		}
				// 	}

				// 	item.totalPrice = item.price * item.quntity;

				// 	store.dispatch('addToCart', item);
				// 	store.commit('message', t('misc.addSuccess'));
				// 	store.commit('popupMode', 'success');
				// 	store.commit('popup');

				// 	emit('closemodal');
			}
			function closePopup() {
				store.commit('closePopup');
			}
			return {
				item,
				qtyPlus,
				optionType,
				storeOption,
				qtyMinus,
				addToCart,
				closemodal,
				selectPdfCopy,
				closePopup,
				getHardCopyBookDetails,
				selectedPrice,
			};
		},
	};
</script>

<style scoped lang="scss">
	.modal::before {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.507);
	}
	.bookinfoModal {
		.woocommerce-product-gallery__wrapper {
			width: 150px;
		}
	}
	.is-rtl {
		p,
		h2,
		div,
		h6 {
			text-align: right !important;
		}
	}
	.card {
		background-color: transparent;
		color: #999;
		text-align: center;
		padding: 5px;
		cursor: pointer;
		margin-right: 8px;
		border: dashed 2px #999;
	}
	.price {
		color: #666 !important;
		font-size: 28px !important;
		margin-bottom: 0px !important;
		line-height: 28px;
	}
	.address {
		margin-bottom: 0 !important;
		font-size: 10px;
	}
	.selectedPrice {
		border-color: #324b9b;
		color: #324b9b;
	}
	.selectedPrice .price,
	.selectedPrice .address {
		color: #324b9b !important;
	}
</style>
