export default {
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "shops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
    "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
    "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])}
  },
  "inputs": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "Subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "your message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your message"])},
    "Enter something": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter something"])},
    "Postal code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
    "company name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
    "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card number"])},
    "Exp Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exp Year"])},
    "Exp date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exp date"])},
    "Exp Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exp Month"])},
    "Place order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place order"])},
    "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])}
  },
  "placeholder": {
    "Please enter your phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your phone."])},
    "Please enter your name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your name."])},
    "Enter Your Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Your Password"])},
    "Enter Your Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Your Email Address"])},
    "Enter Your Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Your Address"])},
    "Enter code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter code"])},
    "card holder name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card holder name"])},
    "Exp Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exp Year"])},
    "Exp Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exp Month"])},
    "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])}
  },
  "buttons": {
    "Recover Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recover Password"])},
    "Remember your password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username or email"])},
    "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "Print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])},
    "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "Continue shopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue shopping"])},
    "Apply coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply coupon"])},
    "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "Save Changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Changes"])},
    "Add New Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Address"])},
    "Login as a vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login as a vendor"])},
    "Write a review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a review"])},
    "See all reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all reviews"])}
  },
  "misc": {
    "Most Viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most Viewed"])},
    "Newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newest"])},
    "please select address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your address"])},
    "you must": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must"])},
    "Select subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select subject"])},
    "Or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or"])},
    "Ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "The image size must be less than 2M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The image size must be less than 2M"])},
    "The image field is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The image field is required"])},
    "Sign in with Google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in with Google"])},
    "Sign in with Facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in with Facebook"])},
    "Sign up with Google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up with Google"])},
    "Sign up with Facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up with Facebook"])},
    "to continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to continue"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "Order Received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Received"])},
    "View": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "Thank youYour order has been received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you. Your order has been received."])},
    "Featured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Featured"])},
    "Free shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free shipping"])},
    "Visa/Master card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa/Master card"])},
    "Customer Reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Reviews"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
    "stars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stars"])},
    "Details please! Your review helps other shoppers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details please! Your review helps other shoppers."])},
    "What did you like or dislike? What should other shoppers know before buying?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What did you like or dislike? What should other shoppers know before buying?"])},
    "Select a rating(required)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a rating(required)"])},
    "3000 characters remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3000 characters remaining"])},
    "Cash on delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash on delivery"])},
    "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "select addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select addresses"])},
    "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
    "phonenumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "Your order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "Edit address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit address"])},
    "shipping addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping addresses"])},
    "are you sure you want to delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete?"])},
    "Create account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])},
    "Already have an account?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])},
    "New Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Address"])},
    "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "Shipping Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Address"])},
    "In Stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Stock"])},
    "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unavailable"])},
    "SHOP BY CATEGORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHOP BY CATEGORY"])},
    "HELP & SETTINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HELP & SETTINGS"])},
    "Username or email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username or email"])},
    "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
    "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello"])},
    "My account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account"])},
    "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["index"])},
    "Reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
    "About publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About publisher"])},
    "About author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About author"])},
    "Add a title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a title"])},
    "Send review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send review"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["changePassword"])},
    "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "Subtotal:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
    "Your cart:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your cart:"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["items"])},
    "Billing details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing details"])},
    "Paymentmethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment methods"])},
    "Order Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Details"])},
    "Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
    "NewPaymentmethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Payment method"])},
    "PaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method"])},
    "EditPaymentmethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit payment method"])},
    "Shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
    "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for"])},
    "you must select your address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select your address"])},
    "Pay now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay now"])},
    "Coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon"])},
    "Coupon code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon code"])},
    "Have a coupon?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have a coupon?"])},
    "Click here to enter your code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to enter your code"])},
    "Free Shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Shipping"])},
    "New address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New address"])},
    "Proceed to checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed to checkout"])},
    "Checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
    "net price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net price"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
    "shipping cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping cost"])},
    "Books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Books"])},
    "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "Orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
    "Downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads"])},
    "Addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresses"])},
    "Account details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account details"])},
    "Wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wishlist"])},
    "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "ADD TO CART": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADD TO CART"])},
    "Customers Also Considered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers Also Considered"])},
    "Authors Books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author's Books"])},
    "Current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
    "Confirm password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
    "New Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "Confirm new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
    "Remember me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
    "Forgot Password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
    "New Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Account"])},
    "New Publisher Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Publisher Account"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "Frequently Asked Questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently Asked Questions"])},
    "Tell Us Your Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell Us Your Message"])},
    "Social Media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Media"])},
    "Contact Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Information"])},
    "Contact description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will answer any questions you may have about our online sales, rights or partnership service right here."])},
    "From your account dashboard you can view your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From your account dashboard you can view your"])},
    "recent orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recent orders"])},
    "manage your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manage your"])},
    "shipping and billing addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shipping and billing addresses"])},
    "and edit your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and edit your"])},
    "password and account details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password and account details"])},
    "Our Mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Mission"])},
    "Our Vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Vision"])},
    "Explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "Customer Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Service"])},
    "Any questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact us"])},
    "Powered by Technomasr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by Technomasr"])},
    "All rights reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved"])},
    "Value must be numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value must be Number"])},
    "This field should be at least 9 characters long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field should be at least 9 numbers long"])},
    "This field should be at least 3 characters long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field should be at least 3 characters long"])},
    "loginRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in/Join"])},
    "Browse categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse categories"])},
    "All Categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Categories"])},
    "By Books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Books"])},
    "By Vendor / publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Vendor / publisher"])},
    "By Author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Author"])},
    "Pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pages"])},
    "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
    "Sort by price: high to low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by price: high to low"])},
    "Show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
    "Sort by popularity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by popularity"])},
    "Default sorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default sorting"])},
    "Sort by newness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by newness"])},
    "Sort by price: low to high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by price: low to high"])},
    "Showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results"])},
    "cart is empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart is empty"])},
    "By review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By review"])},
    "by price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By price"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "Account Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Details"])},
    "Edit Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Account"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "defaultLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default language"])},
    "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By"])},
    "Select country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select country"])},
    "Select government": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select government"])},
    "Select city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select city"])},
    "Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governorate"])},
    "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "Book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book"])},
    "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author"])},
    "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "Add to cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to cart"])},
    "Add to Wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Wishlist"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
    "publishers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["publishers"])},
    "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seller"])},
    "View Cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Cart"])},
    "Cart Totals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart Totals"])},
    "Hardcopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardcopy"])},
    "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pdf"])},
    "both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both"])},
    "Book Format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book Format"])},
    "Choose an option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose an option"])},
    "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publisher"])},
    "Call toll-free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call toll-free"])},
    "Your shopping bag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your shopping bag"])},
    "addSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added Successfully"])},
    "washlist is empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry Washlist is empty"])},
    "Track Your Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track Your Order"])},
    "Authers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authers"])},
    "Our team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["our team"])},
    "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
    "Bestselling Books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestselling Books"])},
    "Explore Books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore Books"])},
    "Value is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value is required"])},
    "The value must be equal to the other value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The confirm password must be equal to the password"])},
    "Search for books by keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for books by keyword"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prev"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])}
  }
}