<template>
  <div class="container mx-auto my-11 row">
    <div class="col-md-4">
      <img src="@/assets/img2.png" alt="ilaw" class="img-fluid" />
    </div>
    <div class="col-md-8 px-11">
      <h2 class="text-start mb-5 title main-title">New Page</h2>
      <div class="description">
        <div class="mb-4 pb-xl-1 ml-xl-4">
          <h6 class="font-weight-medium font-size-4 mb-4">
            What we really do?
          </h6>
          <div class="font-size-2">
            Mauris tempus erat laoreet turpis lobortis, eu tincidunt erat
            fermentum. Aliquam non tincidunt urna. Integer tincidunt nec nisl
            vitae ullamcorper. Proin sed ultrices erat. Praesent varius ultrices
            massa at faucibus. Aenean dignissim, orci sed faucibus pharetra, dui
            mi dignissim tortor, sit amet condimentum mi ligula sit amet augue.
            Pellentesque vitae eros eget enim mollis placerat. Aliquam non
            tincidunt urna. Integer tincidunt nec nisl vitae ullamcorper. Proin
            sed ultrices erat. Praesent varius ultrices massa at faucibus.
            Aenean dignissim, orci sed faucibus pharetra, dui mi dignissim
            tortor, sit amet condimentum mi ligula sit amet augue. Pellentesque
            vitae eros eget enim mollis placerat.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.title {
  font-size: 32px;
  line-height: 32px;
  color: #363f4d;
  font-weight: 500;
  margin-bottom: 20px;
}
</style>
