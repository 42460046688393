export default {
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصل بنا"])},
    "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "shops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتجر"])},
    "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأقسام"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليمات"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغات"])},
    "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العربية"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإنجليزية"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفرنسية"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رجوع"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])}
  },
  "inputs": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموبايل"])},
    "Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
    "Subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموضوع"])},
    "your message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب رسالتك"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
    "Enter something": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب شيئاً ما "])},
    "Postal code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم البريدي"])},
    "Exp date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الانتهاء"])},
    "company name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الشركة"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الرساله"])},
    "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الكارت"])},
    "Exp Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنة الانتهاء"])},
    "Exp Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهر الانتهاء"])},
    "Place order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إكمال الطلب"])},
    "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز الامان"])}
  },
  "placeholder": {
    "Please enter your name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
    "Please enter your phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الموبايل"])},
    "Enter Your Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
    "Enter Your Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])},
    "Enter Your Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
    "Enter code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الكود"])},
    "card holder name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم في الفيزا"])},
    "Exp Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنة الانتهاء"])},
    "Exp Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهر الانتهاء"])},
    "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز الامان"])}
  },
  "buttons": {
    "Recover Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعادة الباسور"])},
    "Remember your password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم او الايميل"])},
    "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال"])},
    "Print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طباعة"])},
    "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفريغ"])},
    "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث"])},
    "Continue shopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابع التسوق"])},
    "Apply coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيق"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "Save Changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ التغييرات"])},
    "Add New Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة عنوان جديد"])},
    "Write a review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب تقييما"])},
    "See all reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض كل التقييمات"])},
    "Login as a vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول كبائع"])}
  },
  "misc": {
    "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
    "Newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاحدث إصدارا"])},
    "please select address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب اضافة عنوان الشحن"])},
    "Or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أو"])},
    "Ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موافق"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكود"])},
    "The image size must be less than 2M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم الصوره يجب الا يزيد عن 2 ميجا"])},
    "The image field is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حقل الصوره مطلوب"])},
    "Select subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموضوع"])},
    "Sign in with Google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول بإستخدام حساب جوجل"])},
    "Sign in with Facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول بإستخدام الفيسبوك"])},
    "Sign up with Google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل بإستخدام حساب جوجل"])},
    "Sign up with Facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل  بإستخدام الفيسبوك"])},
    "you must": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب عليك"])},
    "Thank youYour order has been received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شكرا لك تم استلام طلبك بنجاح"])},
    "to continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للإستمرار"])},
    "Order Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الطلب"])},
    "View Cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض السلة"])},
    "Cart Totals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي السلة"])},
    "Customer Reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أراء العملاء"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المراجعات"])},
    "Visa/Master card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيزا / ماستر كارد"])},
    "Cash on delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع عند الاستلام"])},
    "select addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إختر عنوان الشحن"])},
    "Free shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شحن مجاني"])},
    "Most Viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاكثر مشاهدة"])},
    "stars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نجوم"])},
    "Select a rating(required)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد تصنيف (مطلوب)"])},
    "Details please! Your review helps other shoppers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التفاصيل من فضلك! رأيك يساعد المتسوقين الآخرين."])},
    "What did you like or dislike? What should other shoppers know before buying?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ماذا يعجبك او لا يعجبك؟ ما الذي يجب أن يعرفه المتسوقون الآخرون قبل الشراء؟"])},
    "Featured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متميز"])},
    "3000 characters remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3000 حرف متبقي"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
    "New Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان جديد"])},
    "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اساسي"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بدون"])},
    "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسخة الكترونية"])},
    "Edit address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل العنوان"])},
    "both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلاهما"])},
    "Create account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب"])},
    "Already have an account?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ألديك حساب؟"])},
    "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجراءات"])},
    "phonenumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
    "Your order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلبك"])},
    "Coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكوبون"])},
    "shipping addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عناوين الشحن"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
    "are you sure you want to delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل انت متأكد من الحذف ؟"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
    "Subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجمالي"])},
    "In Stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متوفر"])},
    "Shipping Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الشحن"])},
    "Click here to enter your code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضغط لادخال كوبون الخصم"])},
    "Have a coupon?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ألديك كوبون؟"])},
    "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير متوفر"])},
    "SHOP BY CATEGORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسوق حسب القسم"])},
    "HELP & SETTINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المساعدة & الاعدادات"])},
    "Username or email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم او الايميل"])},
    "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب"])},
    "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا"])},
    "My account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسابي"])},
    "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلب"])},
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
    "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ألست"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
    "Coupon code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود الكوبون"])},
    "Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفهرس"])},
    "Reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المراجعات"])},
    "About publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن الناشر"])},
    "About author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن الكاتب"])},
    "Add a title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة عنوان"])},
    "Send review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال التقييم"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة المرور"])},
    "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إغلاق"])},
    "Subtotal:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":الإجمالي"])},
    "Your cart:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العربة:"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجمالي"])},
    "Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتج"])},
    "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات"])},
    "you must select your address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب إختيار عنوان الشحن "])},
    "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لـ"])},
    "Pay now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدفع الأن"])},
    "Shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشحن"])},
    "Free Shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شحن مجاني"])},
    "New address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان جديد"])},
    "Proceed to checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع"])},
    "Paymentmethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طرق الدفع"])},
    "PaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طريقة الدفع"])},
    "EditPaymentmethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل طريقة الدفع"])},
    "NewPaymentmethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة طريقة دفع "])},
    "Checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع"])},
    "Books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكتب"])},
    "Orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلبات"])},
    "Downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحميلات"])},
    "Billing details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الفاتورة"])},
    "Addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العناوين"])},
    "Account details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الحساب"])},
    "Wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفضلة"])},
    "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
    "ADD TO CART": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف للسلة"])},
    "Customers Also Considered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ينظر العملاء أيضًا"])},
    "Authors Books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كتب المؤلف"])},
    "Current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الحالية"])},
    "Confirm password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور"])},
    "New Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة مرور جديدة"])},
    "Confirm new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور الجديدة"])},
    "Remember me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تذكرني"])},
    "Forgot Password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسيت كلمة المرور؟"])},
    "New Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء حساب"])},
    "New Publisher Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب بائع جديد"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الايميل"])},
    "Frequently Asked Questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسئلة مكررة"])},
    "Tell Us Your Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب رسالتك"])},
    "Social Media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواقع التواصل الاجتماعي"])},
    "Contact Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات التواصل"])},
    "Contact description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنجيب على أي أسئلة قد تكون لديك حول المبيعات أو الحقوق أو خدمة الشراكة عبر الإنترنت هنا."])},
    "From your account dashboard you can view your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من لوحة معلومات حسابك ، يمكنك عرض ملف"])},
    "recent orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلباتك الحديثة"])},
    "manage your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بادارة"])},
    "shipping and billing addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشحن وعنوانين الشحن"])},
    "and edit your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقم بتعديل"])},
    "Our Mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هدفنا"])},
    "Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغة"])},
    "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصيغة"])},
    "Sort by price: high to low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الترتيب حسب السعر: من الأعلى إلى الأقل"])},
    "Sort by popularity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الترتيب حسب الشعبية"])},
    "Default sorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الافتراضي"])},
    "Sort by newness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فرز بالاسماء"])},
    "Sort by price: low to high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by price: low to high"])},
    "By review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" التقييم"])},
    "Show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض"])},
    "Order Received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملخص الطلب"])},
    "Showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النتائج"])},
    "by price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" السعر"])},
    "View": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رجوع"])},
    "shipping cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشحن"])},
    "net price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصافي"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخصم"])},
    "Value must be numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل قيمة صحيحة"])},
    "This field should be at least 9 characters long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يكون علي الاقل 9 أرقام"])},
    "This field should be at least 3 characters long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يكون علي الاقل 3 احرف"])},
    "Our Vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤيتنا"])},
    "Explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفح"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصيغة"])},
    "Book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكتاب"])},
    "Customer Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمة العملاء"])},
    "Browse categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفح الاقسام"])},
    "loginRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول / الانضمام"])},
    "Powered by Technomasr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنفيذ Technomasr"])},
    "All rights reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الحقوق محفوظة"])},
    "Any questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
    "All Categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الاقسام"])},
    "By Books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسب الكتاب"])},
    "By Vendor / publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بواسطه البائع او الناشر"])},
    "By Author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بواسطه المؤلف"])},
    "Account Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الحساب"])},
    "Edit Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الحساب"])},
    "Pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحات"])},
    "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المساعدة"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
    "cart is empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السلة فارغة"])},
    "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدولة"])},
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة التحكم"])},
    "defaultLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغة الافتراضية"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهاتف"])},
    "Call toll-free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم المجاني"])},
    "By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بواسطه"])},
    "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المؤلف"])},
    "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الناشر"])},
    "publishers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الناشرين"])},
    "Select country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار الدولة"])},
    "Select government": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار المحافظة"])},
    "Select city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار المدينة"])},
    "Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظة"])},
    "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
    "Hardcopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسخه مطبوعة"])},
    "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكمية"])},
    "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البائع"])},
    "Add to Wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضف للمفضلة"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاركة"])},
    "Add to cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف الي العربة"])},
    "Book Format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صيغة الكتاب"])},
    "Choose an option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار"])},
    "Your shopping bag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حقيبة التسوق الخاصة بك"])},
    "addSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت الاضافة بنجاح"])},
    "washlist is empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عفوا سلة المفضله فارغة"])},
    "password and account details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور وتفاصيل الحساب"])},
    "Track Your Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتبع طلبك"])},
    "Explore Books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفح الكتاب"])},
    "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشركاء"])},
    "Our team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فريقنا"])},
    "Authers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المؤلفون"])},
    "Bestselling Books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكتب الاكثر مبيعا"])},
    "Search for books by keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكتب كلمة البحث"])},
    "Value is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحقل مطلوب"])},
    "The value must be equal to the other value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمتان المرور غير متشابهتان"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السابق"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي"])}
  }
}