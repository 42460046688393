<template>
  <figure class="not-found mx-auto mt-2">
    <img class="img-fluid" src="@/assets/notfound.svg" alt="ilaw" />
  </figure>
</template>

<script>
export default {}
</script>

<style>
.not-found {
  width: 300px;
}
</style>
