export default {
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous contacter"])},
    "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à propos de nous"])},
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["générale"])},
    "shops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["magasin"])},
    "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["catégories"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langues"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])}
  },
  "inputs": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphoner"])},
    "Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
    "Subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matière"])},
    "your message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["votre message"])},
    "Enter something": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez quelque chose"])},
    "Postal code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
    "company name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
    "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card number"])},
    "Exp Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exp Year"])},
    "Exp Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exp Month"])},
    "Place order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place order"])},
    "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])}
  },
  "placeholder": {
    "Please enter your phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer votre téléphone."])},
    "Please enter your name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'il vous plaît entrez votre nom."])},
    "Enter Your Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez votre mot de passe"])},
    "Enter Your Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre adresse email"])},
    "Enter Your Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre adresse"])},
    "card holder name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card holder name"])},
    "Exp Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exp Year"])},
    "Exp Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exp Month"])},
    "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])}
  },
  "buttons": {
    "Recover Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer mot de passe"])},
    "Remember your password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur ou email"])},
    "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "Save Changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les modifications"])},
    "Add New Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une nouvelle adresse"])},
    "Login as a vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous en tant que vendeur"])},
    "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "Apply coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply coupon"])},
    "Write a review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a review"])},
    "See all reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all reviews"])}
  },
  "misc": {
    "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "New Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Address"])},
    "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "In Stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En stock"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "Ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "The image size must be less than 2M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The image size must be less than 2M"])},
    "The image field is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The image field is required"])},
    "Select subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select subject"])},
    "Book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book"])},
    "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indisponible"])},
    "SHOP BY CATEGORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACHETER PAR CATÉGORIE"])},
    "HELP & SETTINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AIDE & PARAMÈTRES"])},
    "Username or email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur ou email"])},
    "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
    "Email Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
    "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour"])},
    "My account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
    "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ne pas"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La description"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indice"])},
    "Reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
    "About publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos de l'éditeur"])},
    "About author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About author"])},
    "Add a title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un titre"])},
    "Send review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un avis"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["changer le mot de passe"])},
    "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["proche"])},
    "Subtotal:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total:"])},
    "Your cart:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])},
    "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["éléments"])},
    "Shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expédition"])},
    "Free Shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison gratuite"])},
    "New address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle adresse"])},
    "Proceed to checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer à la caisse"])},
    "Books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livres"])},
    "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre"])},
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "Orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordres"])},
    "Downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargements"])},
    "Addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresses"])},
    "Account details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du compte"])},
    "Wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de souhaits"])},
    "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "ADD TO CART": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AJOUTER AU PANIER"])},
    "Customers Also Considered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients également considérés"])},
    "Authors Books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livres d'auteur"])},
    "Current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe actuel"])},
    "Confirm password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez le mot de passe"])},
    "New Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouveau mot de passe"])},
    "Confirm new password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le nouveau mot de passe"])},
    "Remember me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souviens-toi de moi"])},
    "Forgot Password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié?"])},
    "New Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau compte"])},
    "New Publisher Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau compte d'éditeur"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "Frequently Asked Questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions fréquemment posées"])},
    "Tell Us Your Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dites-nous votre message"])},
    "Social Media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des médias sociaux"])},
    "Contact Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées"])},
    "Contact description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous répondrons à toutes vos questions sur notre service de vente en ligne, de droits ou de partenariat ici."])},
    "From your account dashboard you can view your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À partir du tableau de bord de votre compte, vous pouvez afficher votre"])},
    "recent orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernières commandes"])},
    "manage your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gère ton"])},
    "shipping and billing addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adresses de livraison et de facturation"])},
    "and edit your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et modifiez votre"])},
    "password and account details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mot de passe et détails du compte"])},
    "Our Mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre mission"])},
    "Our Vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre vision"])},
    "Explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorer"])},
    "Customer Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Clients"])},
    "Any questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des questions"])},
    "Powered by Technomasr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propulsé par Technomasr"])},
    "All rights reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["©2022 ilaw. Tous les droits sont réservés"])},
    "loginRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter/Rejoindre"])},
    "Browse categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcourir les catégories"])},
    "All Categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toutes catégories"])},
    "By Books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par livres"])},
    "By Vendor / publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par fournisseur / éditeur"])},
    "By Author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par auteur"])},
    "Pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pages"])},
    "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
    "Sort by price: high to low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par prix : décroissant"])},
    "Show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spectacle"])},
    "Sort by popularity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par popularité"])},
    "Default sorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tri par défaut"])},
    "Sort by newness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par nouveauté"])},
    "Sort by price: low to high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par prix : du plus bas au plus élevé"])},
    "Showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montrant"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["résultats"])},
    "By review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par avis"])},
    "by price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par prix"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
    "Account Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du compte"])},
    "Edit Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le compte"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphoner"])},
    "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
    "defaultLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langage par défaut"])},
    "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aider"])},
    "By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par"])},
    "Select country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le pays"])},
    "Select government": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le gouvernement"])},
    "Select city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une ville"])},
    "Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governorate"])},
    "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteure"])},
    "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
    "Add to cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter au panier"])},
    "Add to Wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter à la liste de souhaits"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager"])},
    "publishers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["éditrices"])},
    "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendeuse"])},
    "Hardcopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copie papier"])},
    "Book Format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format du livre"])},
    "Choose an option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisis une option"])},
    "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditrice"])},
    "Call toll-free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appel numéro vert"])},
    "Your shopping bag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier"])},
    "addSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouté avec succès"])},
    "washlist is empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, la liste de souhaits est vide"])},
    "Track Your Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivre votre commande"])},
    "Authers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteures"])},
    "Our team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notre équipe"])},
    "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les partenaires"])},
    "Bestselling Books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livres à succès"])},
    "Explore Books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorer les livres"])},
    "Search for books by keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher des livres par mot-clé"])},
    "Most Viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most Viewed"])},
    "Featured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Featured"])},
    "Free shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free shipping"])},
    "Visa/Master card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa/Master card"])},
    "Customer Reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Reviews"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
    "stars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stars"])},
    "Details please! Your review helps other shoppers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details please! Your review helps other shoppers."])},
    "What did you like or dislike? What should other shoppers know before buying?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What did you like or dislike? What should other shoppers know before buying?"])},
    "Select a rating(required)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a rating(required)"])},
    "3000 characters remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3000 characters remaining"])},
    "Cash on delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash on delivery"])},
    "select addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select addresses"])},
    "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
    "phonenumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "Your order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "Edit address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit address"])},
    "shipping addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping addresses"])},
    "are you sure you want to delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete?"])},
    "Create account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])},
    "Already have an account?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])},
    "Shipping Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Address"])},
    "Billing details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing details"])},
    "Paymentmethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment methods"])},
    "Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
    "NewPaymentmethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Payment method"])},
    "PaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PaymentMethod"])},
    "EditPaymentmethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit payment method"])},
    "Coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon"])},
    "Coupon code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon code"])},
    "Have a coupon?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have a coupon?"])},
    "Click here to enter your code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to enter your code"])},
    "Checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
    "Value must be numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value must be Number"])},
    "This field should be at least 9 characters long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field should be at least 9 numbers long"])},
    "This field should be at least 3 characters long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field should be at least 3 characters long"])},
    "cart is empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart is empty"])},
    "View Cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Cart"])},
    "Cart Totals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart Totals"])},
    "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pdf"])},
    "both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both"])},
    "Value is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value is required"])},
    "The value must be equal to the other value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The confirm password must be equal to the password"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prev"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])}
  }
}