<template>
  <div class="site-features border-top space-1d625">
    <div class="">
      <ul
        class="list-unstyled my-0 list-features d-flex align-items-center justify-content-xl-between overflow-auto overflow-xl-visible"
      >
        <li class="flex-shrink-0 flex-xl-shrink-1 list-feature">
          <div class="media">
            <div
              class="feature__icon font-size-14 text-primary-green text-lh-xs"
            >
              <i class="glyph-icon flaticon-delivery"></i>
            </div>
            <div
              class="media-body"
              :class="$i18n.locale == 'ar' ? ' mr-4' : ' ml-4'"
            >
              <h4 class="feature__title font-size-3">Free Delivery</h4>
              <p class="feature__subtitle m-0">Orders over $100</p>
            </div>
          </div>
        </li>
        <li
          class="flex-shrink-0 flex-xl-shrink-1 separator mx-4 mx-xl-0 border-left h-fixed-80"
          aria-hidden="true"
          role="presentation"
        ></li>
        <li class="flex-shrink-0 flex-xl-shrink-1 list-feature">
          <div class="media">
            <div
              class="feature__icon font-size-14 text-primary-green text-lh-xs"
            >
              <i class="glyph-icon flaticon-credit"></i>
            </div>
            <div
              class="media-body"
              :class="$i18n.locale == 'ar' ? ' mr-4' : ' ml-4'"
            >
              <h4 class="feature__title font-size-3">Secure Payment</h4>
              <p class="feature__subtitle m-0">100% Secure Payment</p>
            </div>
          </div>
        </li>
        <li
          class="flex-shrink-0 flex-xl-shrink-1 separator mx-4 mx-xl-0 border-left h-fixed-80"
          aria-hidden="true"
          role="presentation"
        ></li>
        <li class="flex-shrink-0 flex-xl-shrink-1 list-feature">
          <div class="media">
            <div
              class="feature__icon font-size-14 text-primary-green text-lh-xs"
            >
              <i class="glyph-icon flaticon-warranty"></i>
            </div>
            <div
              class="media-body"
              :class="$i18n.locale == 'ar' ? ' mr-4' : ' ml-4'"
            >
              <h4 class="feature__title font-size-3">Money Back Guarantee</h4>
              <p class="feature__subtitle m-0">Within 30 Days</p>
            </div>
          </div>
        </li>
        <li
          class="flex-shrink-0 flex-xl-shrink-1 separator mx-4 mx-xl-0 border-left h-fixed-80"
          aria-hidden="true"
          role="presentation"
        ></li>
        <li class="flex-shrink-0 flex-xl-shrink-1 list-feature">
          <div class="media">
            <div
              class="feature__icon font-size-14 text-primary-green text-lh-xs"
            >
              <img src="@/assets/logo.png" class="attracts-icon" alt="ilaw" />
              <!-- <i class="glyph-icon flaticon-help"></i> -->
            </div>
            <div
              class="media-body"
              :class="$i18n.locale == 'ar' ? ' mr-4' : ' ml-4'"
            >
              <h4 class="feature__title font-size-3">24/7 Support</h4>
              <p class="feature__subtitle m-0">Within 1 Business Day</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.media {
  .attracts-icon {
    width: 56px;
  }
}
</style>
